const IconWeekender = () => (
    <svg viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.9278 14.7951V36.9991C28.9278 38.1037 28.0324 38.9991 26.9278 38.9991H3C1.89543 38.9991 1 38.1037 1 36.9991V15.8065C1 15.1705 1.30243 14.5725 1.81464 14.1956L13.9002 5.30248C14.6107 4.77965 15.5798 4.78407 16.2855 5.31336L17.617 6.31199"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M26.9453 1V11.2703"
            stroke="#feca01"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M21.8125 6.13477L32.0828 6.13477"
            stroke="#feca01"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export default IconWeekender;
